import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomDeviceDetectService {

  private deviceInfoSubject = new BehaviorSubject<string>('Unknown');
  deviceInfo$: Observable<string> = this.deviceInfoSubject.asObservable();


  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.detectDevice();
  }

  private detectDevice(): void {
    if (isPlatformBrowser(this.platformId)) {
      const userAgent = navigator.userAgent;

      let deviceInfo = 'Unknown';
      if (/iPad|Macintosh/.test(userAgent) && 'ontouchend' in document) {
        deviceInfo = 'tablet';
      } else if (this.deviceService.isDesktop()) {
        deviceInfo = 'desktop';
      } else if (this.deviceService.isTablet()) {
        deviceInfo = 'tablet';
      } else if (this.deviceService.isMobile()) {
        deviceInfo = 'mobile';
      }

      // 更新 BehaviorSubject 的值
      this.deviceInfoSubject.next(deviceInfo);
    } else {
      // 非瀏覽器環境
      this.deviceInfoSubject.next('非瀏覽器環境');
    }

  }



}
